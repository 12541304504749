import React, { useEffect, useState } from "react";
import { Button, CustomInput, Table } from "reactstrap";
import axios from "axios";
import { connect } from "react-redux";
import { toHumanFileSize } from "../utils/Misc";
import { Link } from "gatsby";

const Stats = ({ token }) => {

	const [stats, setStats] = useState({ error: false });
	const [checked, setChecked] = useState(false);
	const [pagination, setPagination] = useState(0);

	const pageSize = 20;

	const monthAgoDate = new Date();
	monthAgoDate.setDate(monthAgoDate.getDate() - 30);

	useEffect(() => {
		async function axiosGetStats() {
			try {
				const res = await axios.get(`${ process.env.GATSBY_API_BASE_URL }/api/images/stats`,
					{ headers: { "Authorization": `Bearer ${ token }` } });
				const imagesWithinMonth = res.data.images.filter(i => +new Date(i.timestampCreated) > +monthAgoDate);
				setStats({ images: res.data.images, status: res.status, usage: { ...res.data.usage, monthIn: imagesWithinMonth.reduce((acc, cur) => acc + cur.sizeIn, 0), monthOut: imagesWithinMonth.reduce((acc, cur) => acc + cur.sizeOut, 0) } });
			} catch (err) {
				setStats({ error: err });
			}
		}
		axiosGetStats();
	}, [token]);

	return (
		<div>
			{ stats.usage &&
			<>
				<h2 className="text-center font-weight-bold">Statistiken</h2>
				<div className="d-flex font-weight-bold align-items-center justify-content-center">
					<h3 className="mb-0 noselect flex-1 text-right">30 Tage</h3>
					<div className="px-3">
						<CustomInput type="switch" id="timespan" name="customSwitch" className="switch-double" checked={ checked }  onChange={ e => setChecked(e.target.checked) } />
					</div>
					<h3 className="mb-0 noselect flex-1">Gesamt</h3>
				</div>
				<div className="d-flex align-items-center justify-content-around mt-5 text-center">
					<div>
						<h1 className="font-weight-bold mb-0">{ toHumanFileSize((checked ? stats.usage.totalIn : stats.usage.monthIn) || 0) }</h1>
						<p className="font-italic color-stats">Gesamt original</p>
					</div>
					<div>
						<h4 className="color-stats font-weight-bold mb-0">Bilder Komprimierung</h4>
						{ !checked && <p>(Seit { monthAgoDate.toISOString().split('T')[0] })</p> }
					</div>
					<div>
						<h1 className="font-weight-bold mb-0">{ toHumanFileSize((checked ? stats.usage.totalOut : stats.usage.monthOut) || 0) }</h1>
						<p className="font-italic color-stats">Gesamt komprimiert</p>
					</div>
				</div>
			</>
			}
			{ stats.images &&
			<>
				<h3 className="mt-5 text-center">Verlauf</h3>

				{ stats.images.length === 0 &&
					<p className="color-subtext">Du hast bis jetzt noch keine Bilder komprimiert. Klicke hier und <Link to="/" className="color-orange">komprimiere</Link> welche!</p>
				}

				{ stats.images.length > 0 &&
					<>
						<Table size="sm" borderless hover responsive  className="color-stats">
							<thead>
							<tr>
								{/*<th>Hash</th>*/}
								<th>Datum</th>
								<th>Originalgröße</th>
								<th>Komprimiert</th>
								<th>Typ</th>
							</tr>
							</thead>
							<tbody>
							{ stats.images.slice(pagination*pageSize, pagination*pageSize+pageSize).map(e => (
								<tr>
									{/*<td>{ e.hash }</td>*/}
									<td>{ e.timestampCreated }</td>
									<td>{ toHumanFileSize(e.sizeIn) }</td>
									<td>{ toHumanFileSize(e.sizeOut) }</td>
									<td>{ e.mime }</td>
								</tr>
							)) }
							</tbody>
						</Table>
						<div className="float-right">
							{ (pagination > 0) &&
							<Button color="secondary" size="sm" className="mr-2"
									onClick={ () => setPagination(pagination-1) }>Zurück</Button>
							}
							{ pagination < (stats.images.length/pageSize-1) &&
							<Button color="secondary" size="sm" className="mr-2"
									onClick={ () => setPagination(pagination+1) }>Weiter</Button>
							}
						</div>
					</>
				}
			</>
			}
		</div>
	)
};



export default connect(state => ({
	token: state.user.token,
	user: state.user.user,
}))(Stats);
