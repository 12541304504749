import React from "react";
import { connect } from "react-redux";
import Redirect from "./Redirect";
import { isAuthed } from "../utils/Misc";

const AuthedRoute = ({ token, inverted, children }) => {
	if (inverted) {
		return isAuthed(token) ? (<Redirect to="/dashboard" />) : (<>{ children }</>);
	} else {
		return isAuthed(token) ? (<>{ children }</>) : (<Redirect to="/login" />);
	}
};

export default connect(state => ({
	token: state.user.token,
}))(AuthedRoute);
