import { Button, Col, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { getAxiosAuthHeaders } from "../utils/Misc";
import { updateUser } from "../state/user";

const UserData = ({ dispatch, user, token }) => {

	const [userData, setUserData] = useState({
		firstName: user.firstName,
		lastName: user.lastName,
		status: "",
	});


	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!!userData.firstName && !!userData.lastName) {
			setUserData({ ...userData, status: "SENT" });
			try {
				await axios.put(`${ process.env.GATSBY_API_BASE_URL }/api/users/me`, {
					firstName: userData.firstName,
					lastName: userData.lastName,
				}, {
					headers: getAxiosAuthHeaders(token),
				});
				setUserData({ ...userData, status: "SUCCESS" });
				dispatch(updateUser(userData.firstName, userData.lastName));
			} catch (err) {
				console.error(err);
				setUserData({ ...userData, status: "ERROR" });
			}
		}
	};

	return (
		<Col sm="12" lg="6" className="text-center">
			<h2 className="font-weight-bold mb-3">Benutzerdaten</h2>
			<p className="mb-2"><span className="font-weight-bold">E-Mail:</span> { user.email }</p>
			<Form onSubmit={ e => handleSubmit(e) } className="mw-75 d-block mx-auto">
				<FormGroup>
					<Label for="firstName">Vorname</Label>
					<Input type="text" name="firstName" id="firstName" placeholder="Max"
						   className="round"
						   value={ userData.firstName }
						   invalid={ !userData.firstName }
						   valid={ !!userData.firstName }
						   onChange={ e => setUserData({ ...userData, firstName: e.target.value }) }
					/>
					<FormFeedback>
						Ein Vorname wäre schon toll!
					</FormFeedback>
				</FormGroup>
				<FormGroup>
					<Label for="lastName">Nachname</Label>
					<Input type="text" name="lastName" id="lastName" placeholder="Mustermann"
						   className="round"
						   value={ userData.lastName }
						   invalid={ !userData.lastName }
						   valid={ !!userData.lastName }
						   onChange={ e => setUserData({ ...userData, lastName: e.target.value }) }
					/>
					<FormFeedback>
						Ein Nachname wäre schon toll!
					</FormFeedback>
				</FormGroup>
				{ userData.status === "ERROR" &&
				<p className="feedback-error">{ userData.status }</p> }
				<Button color="primary" block className="round mt-4 py-2"
						disabled={ (!userData.firstName && !userData.lastName)
						|| (userData.firstName === user.firstName && userData.lastName === user.lastName)
						|| userData.status === "SENT" }>Aktualisieren</Button>
			</Form>
		</Col>
	)
};

export default connect(state => ({
	token: state.user.token,
	user: state.user.user,
}))(UserData);
