import React, { useState } from "react";

import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContentContainer from "../components/ContentContainer";
import AuthedRoute from "../components/AuthedRoute";
import { Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Stats from "../components/Stats";
import ChangePassword from "../components/ChangePassword";
import { connect } from "react-redux";
import UserData from "../components/UserData";
import AdminDashboard from "../components/AdminDashboard";
import APIDashboard from "../components/APIDashboard";

const Dashboard = ({ user }) => {
	const [activeTab, setActiveTab] = useState("1");

	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	return (
		<AuthedRoute>
			<Layout>
				<SEO title="Dashboard"/>
				<ContentContainer title="Dashboard" half>
					<Nav pills className="mb-5 justify-content-center font-weight-bold">
						<NavItem>
							<NavLink
								className={ activeTab === "1" && "active" }
								onClick={ () => toggle("1") }>
								Statistiken
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={ activeTab === "2" && "active" }
								onClick={ () => toggle("2") }>
								Profil
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={ activeTab === "3" && "active" }
								onClick={ () => toggle("3") }>
								Passwort ändern
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={ activeTab === "4" && "active" }
								onClick={ () => toggle("4") }>
								API
							</NavLink>
						</NavItem>
						{	user.admin &&
							<NavItem>
								<NavLink
									className={ activeTab === "999" && "active" }
									onClick={ () => toggle("999") }>
									Admin
								</NavLink>
							</NavItem>
						}
					</Nav>
					<TabContent activeTab={ activeTab }>
						<TabPane tabId="1">
							<Row>
								<Col sm="12">
									<Stats/>
								</Col>
							</Row>
						</TabPane>
						<TabPane tabId="2">
							<Row>
								<UserData />
								<Col sm="12" lg="6" className="text-center mt-5 mt-lg-0">
									<h2 className="font-weight-bold">Account Löschen</h2>
									<p>Du willst uns doch wohl nicht wirklich verlassen, oder? ☹ Mit der Lösung deines Accounts kannst du unsere premium Features nichtmehr nutzen und verlierst deine persönlichen Statistiken.</p>
									<Link to="/delete-account">
										<Button color="danger" block className="round mt-4 py-2">Account Löschen</Button>
									</Link>
								</Col>
							</Row>
						</TabPane>
						<TabPane tabId="3">
							<Row>
								<Col sm="12">
									<ChangePassword/>
								</Col>
							</Row>
						</TabPane>
						<TabPane tabId="4">
							<Row>
								<Col sm="12">
									<APIDashboard/>
								</Col>
							</Row>
						</TabPane>
						{   user.admin &&
							<TabPane tabId="999">
								<Row>
									<Col sm="12">
										<AdminDashboard/>
									</Col>
								</Row>
							</TabPane>
						}
					</TabContent>
				</ContentContainer>
			</Layout>
		</AuthedRoute>
	);
};

export default connect(state => ({
	user: state.user.user,
}))(Dashboard);
