import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import axios from "axios";
import { connect } from "react-redux";
import { toHumanFileSize } from "../utils/Misc";

const AdminDashboard = ({ token }) => {

	const [users, setUsers] = useState({
		error: false,
		signuplessStats: {},
		data: []
	});

	const [pagination, setPagination] = useState(0);
	const pageSize = 20;

	useEffect(() => {
		async function axiosGetData() {
			try {
				const res = (await Promise.all([
					axios.get(`${ process.env.GATSBY_API_BASE_URL }/api/users/admin`, { headers: { "Authorization": `Bearer ${ token }` } }),
					axios.get(`${ process.env.GATSBY_API_BASE_URL }/api/images/admin/stats`, { headers: { "Authorization": `Bearer ${ token }` } }),
				])).map(r => r.data);
				const userData = res[0].map(user => ({...user, ...res[1][user.id], reset: false}));
				setUsers({ ...users, signuplessStats: res[1]["null"], data: userData });
			} catch (err) {
				setUsers({ error: err });
			}
		}
		axiosGetData();
	}, [token]);

	async function userSetDisabled(id, disable) {
		try {
			await axios.post(`${ process.env.GATSBY_API_BASE_URL }/api/users/admin/disable/${id}`,
				{ disable },
				{ headers: { "Authorization": `Bearer ${ token }` } });
			setUsers({ ...users, data: users.data.map(user => (user.id === id ? { ...user, disabled: disable } : user))});
		} catch (err) {
			console.error(err);
			setUsers({ error: err });
		}
	}

	async function userDelete(id) {
		try {
			await axios.post(`${ process.env.GATSBY_API_BASE_URL }/api/users/admin/delete/${id}`, {},
				{ headers: { "Authorization": `Bearer ${ token }` } });
			setUsers({ ...users, data: users.data.filter(user => user.id !== id) });
		} catch (err) {
			console.error(err);
			setUsers({ error: err });
		}
	}

	async function userResetPassword(email) {
		try {
			await axios.post(`${ process.env.GATSBY_API_BASE_URL }/api/users/resetPassword`,
				{ email });
			setUsers({ ...users, data: users.data.map(user => (user.email === email ? { ...user, reset: true } : user))});
		} catch (err) {
			console.error(err);
			setUsers({ error: err });
		}
	}

	return (
		<div>
			<h3 className="text-center">Admin Dashboard</h3>

			{ users.data.length === 0 &&
			<p className="color-subtext text-center">No users loaded. Check the console error log or the server connectivity.</p>
			}

			{ users.error &&
			<p className="text-danger">An error has occured. Check the error log.</p>
			}

			{ users.data.length > 0 &&
			<>
				<Table size="sm" borderless hover responsive  className="color-stats">
					<thead>
					<tr>
						<th>E-Mail</th>
						<th>Vorname</th>
						<th>Nachname</th>
						<th>Registriert</th>
						<th>Deaktiviert</th>
						<th>Admin</th>
						<th>In (Total)</th>
						<th>Out (Total)</th>
						<th>In (30T)</th>
						<th>Out (30T)</th>
						<th></th>
						<th></th>
						<th></th>
					</tr>
					</thead>
					<tbody>
					{ users.data.slice(pagination*pageSize, pagination*pageSize+pageSize).map(user => (
						<tr>
							{/*<td>{ e.hash }</td>*/}
							<td>{ user.email }</td>
							<td>{ user.firstName }</td>
							<td>{ user.lastName }</td>
							<td>{ user.timestampCreated }</td>
							<td>{ user.disabled }</td>
							<td>{ user.admin }</td>
							<td>{ toHumanFileSize(user.totalIn || 0) }</td>
							<td>{ toHumanFileSize(user.totalOut || 0) }</td>
							<td>{ toHumanFileSize(user["30dIn"] || 0) }</td>
							<td>{ toHumanFileSize(user["30dOut"] || 0) }</td>
							<td><Button color={ user.disabled ? "primary" : "danger" } size="sm" disabled={user.admin}
										onClick={ () => userSetDisabled(user.id, !user.disabled) }>{ user.disabled ? "Enable" : "Disable" }</Button></td>
							<td><Button color="danger" size="sm" disabled={user.admin}
										onClick={ () => userDelete(user.id) }>Delete</Button></td>
							<td><Button color="secondary" size="sm" disabled={user.reset}
										onClick={ () => userResetPassword(user.email) }>Reset PW</Button></td>
						</tr>
					)) }
					</tbody>
				</Table>
				<div className="float-right">
					{ (pagination > 0) &&
					<Button color="secondary" size="sm" className="mr-2"
							onClick={ () => setPagination(pagination-1) }>Zurück</Button>
					}
					{ pagination < (users.data.length/pageSize-1) &&
					<Button color="secondary" size="sm" className="mr-2"
							onClick={ () => setPagination(pagination+1) }>Weiter</Button>
					}
				</div>
			</>
			}
		</div>
	)
};



export default connect(state => ({
	token: state.user.token,
	user: state.user.user,
}))(AdminDashboard);
