import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import axios from "axios";
import { connect } from "react-redux";
import { getAxiosAuthConfig } from "../utils/Misc";
import { Link } from "gatsby";

const APIDashboard = ({ token }) => {

	const [keys, setKeys] = useState({
		error: false,
		refresh: false,
		data: []
	});

	useEffect(() => {
		async function axiosGetData() {
			try {
				const res = await axios.get(`${ process.env.GATSBY_API_BASE_URL }/api/keys`, getAxiosAuthConfig(token));
				setKeys({ ...keys, data: res.data });
			} catch (err) {
				setKeys({ error: err });
			}
		}
		axiosGetData();
	}, [token, keys.refresh]);

	/*async function userSetDisabled(id, disable) {
		try {
			await axios.post(`${ process.env.GATSBY_API_BASE_URL }/api/users/admin/disable/${id}`,
				{ disable },
				{ headers: { "Authorization": `Bearer ${ token }` } });
			setUsers({ ...users, data: users.data.map(user => (user.id === id ? { ...user, disabled: disable } : user))});
		} catch (err) {
			console.error(err);
			setUsers({ error: err });
		}
	}*/

	async function deleteKey(id) {
		try {
			await axios.delete(`${ process.env.GATSBY_API_BASE_URL }/api/keys/${id}`, getAxiosAuthConfig(token));
			setKeys({ ...keys, data: keys.data.filter(key => key.id !== id) });
		} catch (err) {
			console.error(err);
			setKeys({ error: err });
		}
	}

	async function addKey() {
		try {
			const res = await axios.post(`${ process.env.GATSBY_API_BASE_URL }/api/keys`, {}, getAxiosAuthConfig(token));
			setKeys({ ...keys, data: [ ...keys.data, res.data ]  });
		} catch (err) {
			console.error(err);
			setKeys({ error: err });
		}
	}

	return (
		<div>
			<h3 className="text-center">API Keys</h3>

			{ keys.data.length === 0 &&
			<p className="color-subtext text-center">Noch kein Key erstellt. Erstelle unten deinen ersten Key!</p>
			}

			{ keys.error &&
			<p className="text-danger">Oh oh. Ein Fehler ist aufgetreten. Bitte kontaktiere uns.</p>
			}

			{ keys.data.length > 0 &&
			<>
				<Table size="sm" borderless hover responsive className="color-stats">
					<thead>
					<tr>
						<th>Key</th>
						<th>Erstellt</th>
						<th></th>
					</tr>
					</thead>
					<tbody>
					{ keys.data.map(key => (
						<tr>
							<td>{ key.id }</td>
							<td>{ key.timestampCreated }</td>
							<td><Button color="danger" size="sm" className="float-right"
										onClick={ () => deleteKey(key.id) }>Löschen</Button></td>
						</tr>
					)) }
					</tbody>
				</Table>
			</>
			}
			<div className={ keys.data.length === 0 ? "text-center" : "float-right" }>
				<Button color="secondary" size="" className="mt-2"
						onClick={ addKey }>Key erstellen</Button>
			</div>
			<p className="color-subtext text-center mt-2">Schau in unser <Link to="/faq" className="color-orange">FAQ</Link> für mehr Infos!</p>
		</div>
	)
};

export default connect(state => ({
	token: state.user.token,
	user: state.user.user,
}))(APIDashboard);
