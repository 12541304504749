import React, { useEffect, useState } from "react";

import axios from "axios";
import { connect } from "react-redux";
import {
	Button, Form,
	FormFeedback,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Label,
} from "reactstrap";
import LockIcon from "../images/icon-lock.svg";

const ChangePassword = ({ token }) => {

	const [input, setInput] = useState({ touched: false, valiation: false, password: "", confirmedPassword: "", error: false, success: false });

	useEffect(() => {
		const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)\S{8,}$/;
		setInput({ ...input, validation: passwordRegex.test(input.password), error: false });
	}, [input.password]);

	const changePassword = async e => {
		e.preventDefault();
		try {
			await axios.post(`${ process.env.GATSBY_API_BASE_URL }/api/users/password`,
				{ password: input.password },
				{ headers: { "Authorization": `Bearer ${ token }` } });
			setInput({ touched: false, valiation: false, password: "", confirmedPassword: "", error: false, success: "Passwort erfolgreich geändert!" })
		} catch (error) {
			setInput({ ...input, error: error.response.statusText })
		}
	};

	return (
		<Form onSubmit={ changePassword } className="mw-50 d-block mx-auto">
			<FormGroup>
				<Label for="password">Neues Passwort</Label>
				<InputGroup>
					<InputGroupAddon addonType="prepend">
						<InputGroupText className="round-left input-group-icon">
							<img src={LockIcon} alt="lock icon prefix" />
						</InputGroupText>
					</InputGroupAddon>
					<Input type="password" name="password" id="password" placeholder="********"
						   className="round-right"
						   value={ input.password }
						   valid={ input.touched && input.valiation }
						   invalid={ input.touched && !input.valiation }
						   onChange={ e => setInput({ ...input, password: e.target.value }) } />
				</InputGroup>
				<FormFeedback valid>
					<span role="img" aria-label="thumbsup emoji">👍</span>
				</FormFeedback>
				<FormFeedback>
					Passwort unsicher! (8 Zeichen, min. 1 Buchstabe, min. 1 Zahl)
				</FormFeedback>
			</FormGroup>
			<FormGroup>
				<Label for="confirmPassword">Neues Passwort bestätigen</Label>
				<InputGroup>
					<InputGroupAddon addonType="prepend">
						<InputGroupText className="round-left input-group-icon">
							<img src={LockIcon} alt="lock icon prefix" />
						</InputGroupText>
					</InputGroupAddon>
					<Input type="password" name="confirmPassword" id="confirmPassword"
						   placeholder="********"
						   className="round-right"
						   value={ input.confirmedPassword }
						   valid={ input.touched && input.confirmedPassword && input.confirmedPassword === input.password }
						   invalid={ input.touched && (!input.confirmedPassword || input.confirmedPassword !== input.password) }
						   onChange={ e => setInput({ ...input, confirmedPassword: e.target.value }) }
					/>
				</InputGroup>
				<FormFeedback>
					Passwörter müssen übereinstimmen!
				</FormFeedback>
			</FormGroup>
			<p className="feedback-error">{ input.error }</p>
			<p className="feedback-success">{ input.success }</p>
			<Button color="primary" block className="round mt-4 py-2">Passwort ändern</Button>
		</Form>
	);
};

export default connect(state => ({
	token: state.user.token,
}))(ChangePassword);
